import { createWebHistory, createRouter } from "vue-router";
import i18n from "../main"

import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import Results from "@/views/Results.vue";
import Assessment from "@/views/Assessment.vue";
import Error from "@/views/Error.vue";
import Close from "@/views/Close.vue";
import Cgu from "@/views/Cgu.vue";
import Thanks from "@/views/Thanks.vue";
import configApp from '../config/config'

const routes = [
    {
        path: "/login/:uuid?",
        name: "Login",
        component: Login,
    },
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: '/assessment/:type?/:question(\\d+)*',
        name: "Assessments",
        component: Assessment,
    },
    {
        path: "/results",
        name: "Results",
        component: Results,
    },
    {
        path: "/thanks",
        name: "Thanks",
        component: Thanks
    },
    {
        path: "/error",
        name: "Error",
        component: Error,
    },
    {
        path: "/close",
        name: "Close",
        component: Close,
    },
    {
        path: "/terms-of-use",
        name: "Terms",
        component: Cgu,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeResolve(async to => {

    if(location.host.includes("cloudfront.net")){
        document.location.href = configApp.AF_PLATFORM;
    }


    // check if platform is open
    if(configApp.PLATFORM_OPEN != 1 && to.name != "Close"){
        router.push({ name: 'Close'})
    }

    if(configApp.PLATFORM_OPEN == 1 && to.name == "Close"){
        router.push({ name: 'Home'})
    }

    // check if user is logged
    if(to.name != "Login" && to.name != "Error"  && to.name != "Terms"){
        let userID = localStorage.getItem('af-parisjo-user');

        if(userID == undefined){
           router.push({ name: 'Login'})
        }
    }

    // check if user has accepted CGU
    if(to.name != "Login" && to.name != "Home" && to.name != "Error"  && to.name != "Terms"){
        let cguValidated = localStorage.getItem('af-parisjo-cgu');

        if(cguValidated != "true"){
            router.push({ name: 'Home'})
        }
    }

});

export default router;

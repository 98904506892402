<template>
    <div class="link">
        <button @click.stop.prevent="handleClick" class="link__default link__default--pink" :disabled="disabled" :aria-label="description">
            {{ title }}
            <img src="/dist/img/blue-arrow.svg" alt="" />
        </button>
    </div>
</template>


<script>

export default {
    name: "ButtonContinue",
    props: ['title', 'description', 'link', 'action', 'disabled'],
    setup(){},
    methods: {
      handleClick() {
        this.$emit('onClick')
      }
    }
}
</script>
<template>
  <Question class="question" v-if="questionElementObject" :number="questionNumber" :questionElement="questionElementObject" @onResponseProposal="handleResponseClick" />
</template>


<script>
import { computed, onMounted, onBeforeMount, defineComponent, ref } from "vue";
import { useRouter } from 'vue-router';
import Question from '../components/questions/Question.vue';
import { useI18n } from 'vue-i18n'

import assessmentModelFr from "../assets/assessments/drive/fr.json";
import assessmentModelEn from "../assets/assessments/drive/en.json";

export default defineComponent({
  name: "Drive",
  components: { Question },
  props: {},
  setup() {
    const locale = useI18n();
    const router = useRouter();
    
    const assessmentObjectList = computed(() => {
      if(locale.locale.value == 'fr'){
          return assessmentModelFr;
      }else{
          return assessmentModelEn;
      }
    });

    const questionNumber = ref(90);
    const currentQuestion = ref(1);
    const questionElementObject= ref(null);

    function sendResultAndRedirect(){
      router.push({ name: 'Results'})
    }

    function loadSpecificQuestion(index){
      if(index < 131){
        index = 131
      }
      if(index>220){
        sendResultAndRedirect();
      }

      questionNumber.value=221-index;
      currentQuestion.value = (index);
      questionElementObject.value = assessmentObjectList.value[currentQuestion.value];
    }

    function loadCurentQuestion(){
      questionNumber.value--;
      currentQuestion.value++;
      if(currentQuestion.value>220){
          router.push({ name: 'Results'})
      }
      questionElementObject.value = assessmentObjectList.value[currentQuestion.value];
    }

    function handleResponseClick(itemId, proposalId, dimension) {
      let assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-drive');
      
      if(assessmentShapeUser){
        assessmentShapeUser =  JSON.parse(assessmentShapeUser);
      }else{
        assessmentShapeUser = {};
      }

      assessmentShapeUser[itemId] = { id: proposalId, dim: dimension };

      localStorage.setItem('af-parisjo-assessment-drive',JSON.stringify(assessmentShapeUser));

      loadCurentQuestion();
    }

    onMounted(() => {
        // check if shape is allready done
        let assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-shape');
        if(!assessmentShapeUser){
          router.push({ name: 'Assessments',params: { type: 'shape'}})
        }else{
          assessmentShapeUser = JSON.parse(assessmentShapeUser);
          if(Object.keys(assessmentShapeUser).length < 90){
            router.push({ name: 'Assessments',params: { type: 'shape'}})
          }
        }

         // @todo => Check if assessment are already done
        let assessmentDriveUser = localStorage.getItem('af-parisjo-assessment-drive');
        let lastIndex = 1;

        if(assessmentDriveUser){
            assessmentDriveUser =  JSON.parse(assessmentDriveUser);
            if(Object.keys(assessmentDriveUser).length == 90){
              sendResultAndRedirect()
            }

            for(var i in assessmentDriveUser){
              lastIndex = i;
            }
            lastIndex++;
        }

        loadSpecificQuestion(lastIndex);
    })

    onBeforeMount(() => {
        let cguValidated = localStorage.getItem('af-parisjo-cgu');

        if(!cguValidated){
            router.push({ name: 'Home'})
        }
    })

    return {
      handleResponseClick,
      questionNumber,
      questionElementObject
    };
  }
});
</script>

export function removeLocalStorageHelper() {
    /*console.log('---- Flush Local DB ----')*/
    localStorage.removeItem('af-parisjo-cgu')
    localStorage.removeItem('af-parisjo-assessment-shape')
    localStorage.removeItem('af-parisjo-assessment-drive')
    localStorage.removeItem('af-parisjo-userScore')
    localStorage.removeItem('af-parisjo-mission-choice')
    localStorage.removeItem('af-parisjo-user')
    //localStorage.removeItem('af-parisjo-user-custom')
}

<template>
    <main class="question-example__main" role="main">
        <HeaderBanner/>
        <div class="question-example__container">
            <h1 class="page-title uppercase">{{ t("test.midpoint_title") }}</h1>
            <div class="description">
                <p class="paragraph" v-html="DOMPurify.sanitize(t('test.midpoint_description', {
                up_key: upKey,
                down_key: downKey,
                }))">
                </p>
            </div>
            <div class="question-example__simulation">
                <h2 class="page-title uppercase">{{ t('test.example') }}</h2>
                <p class="bold">{{ t('test.sentence_b') }}</p>
                <ul class="list-example">
                    <li class="list-example__items">
                        <button class="btn-question toggle-btn" aria-pressed="false" id="response1" @click="handleContinueClick">
                            {{ t('test.option_1_b') }}
                            (<span class="ico-arrow-up"></span>)
                        </button>
                    </li>
                    <li class="list-example__items">
                        <button class="btn-question toggle-btn" aria-pressed="false" id="response2" @click="handleContinueClick">
                            {{ t('test.option_2_b') }}
                            (<span class="ico-arrow-down"></span>)
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</template>

<script>
import { onMounted, onBeforeUnmount} from "vue";
import HeaderBanner from "../header/Header-Banner.vue";
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import DOMPurify from 'dompurify'


export default {
  name: "QuestionContinue",
  components: { HeaderBanner },
  setup() {
      const router = useRouter()

      const upKey = '<span class="ico-arrow-up"></span>'
      const downKey = '<span class="ico-arrow-down"></span>'
      
        removeListennerCustom();

        function eventHandler(event) {
        if(event.key === "ArrowUp"){
            removeListennerCustom();
            setTimeout(() => { 
                document.getElementById('response1').classList.add("active");
                setTimeout(() => { 
                document.getElementById('response1').click(); 
                document.getElementById('response1').classList.remove("active");
                document.addEventListener('keyup', eventHandler);
                }, 200);
            }, 400);                  
            
        }
        if(event.key === "ArrowDown"){
            removeListennerCustom();
            setTimeout(() => { 
                document.getElementById('response2').classList.add("active");
                setTimeout(() => { 
                document.getElementById('response2').click(); 
                document.getElementById('response2').classList.remove("active");
                document.addEventListener('keyup', eventHandler);
                }, 200);

            }, 400);   
            
        }
    }
    function removeListennerCustom(){
        document.removeEventListener('keyup',eventHandler)
    }

      function handleContinueClick() {
            // Redirect to next step
            removeListennerCustom()
            router.push({ name: 'Assessments',params: { type: 'drive'}})
      }

      onMounted(() => {
            // check if shape is allready done
            let assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-shape');
            if(!assessmentShapeUser){
            router.push({ name: 'Assessments',params: { type: 'shape'}})
            }else{
            assessmentShapeUser = JSON.parse(assessmentShapeUser);
            if(Object.keys(assessmentShapeUser).length < 90){
                router.push({ name: 'Assessments',params: { type: 'shape'}})
            }
            }

            // @todo => Check if assessment are already done
            let assessmentDriveUser = localStorage.getItem('af-parisjo-assessment-drive');

            if(assessmentDriveUser){
                router.push({ name: 'Assessments',params: { type: 'drive'}})
            }

             document.addEventListener('keyup', eventHandler);
        })
    onBeforeUnmount(() => {
        removeListennerCustom()
    });

      const { t } = useI18n({})

    //window.document.title = t('title')
    window.document.title = "Exemple - Questionnaire d'orientation - JO24 Programme des volontaires"

      return {
            handleContinueClick,
            t,
            DOMPurify,
            upKey,
            downKey
      }
  }
};
</script>

export default {
    'AWS_API_KEY': 'tz0tLIecgU2UJAxbzpV1VaIbKPScljlo3aB4aWCe',
    'AWS_BASE_URL': 'https://a5p5evnm47.execute-api.eu-west-3.amazonaws.com/Prod/',
    'ATOS_PLATFORM_URL': 'https://volontaire.paris2024.org',
    'AF_PLATFORM' : 'https://questionnaire-volontaire.paris2024.org',
    'PLATFORM_OPEN': 1,
    'LOAD_TEST': 0,
    'ATOS_ENABLE': 0,
    //'GIGYA_KEY': '4_DA1QnxKUBQm1C4jfMD2Stg'
    'GIGYA_KEY': '4_NJJrXj3BQz34ffVpp1d8eg'
};

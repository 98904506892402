
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import App from './App.vue'
import router from './router'
import messages from './locales';

Bugsnag.start({
    apiKey: '2d20211c6b46280cc381171de80dcf91',
    plugins: [new BugsnagPluginVue()]
  })

const bugsnagVue = Bugsnag.getPlugin('vue')


const pinia = createPinia()

/* CHANGE LANGUAGE IF "lang" parameter is detected in URL */
let localCutom = "fr"
let localLangStorage = localStorage.getItem('af-parisjo-lang')
const urlParams = new URLSearchParams(window.location.search);
if(urlParams.has('lang')){
    localCutom = urlParams.get('lang');
    if(localCutom.toUpperCase() == "ENG"){
        localCutom = 'en'
    }
    if(localCutom.toUpperCase() == "FRA"){
        localCutom = 'fr'
    }
}else if(localLangStorage){
    localCutom=localStorage.getItem('af-parisjo-lang')
}

localStorage.setItem('af-parisjo-lang', localCutom);

// Add user temp (ex : 0a2502c2c7244a61bd746a72ad522bb0)
let userCustom = ''
if(urlParams.has('u')){
    userCustom = urlParams.get('u');
    localStorage.setItem('af-parisjo-user-custom', userCustom);
}


const i18n = createI18n({
    locale: localCutom.toLowerCase(), // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

  
createApp(App).use(bugsnagVue).use(i18n).use(pinia).use(router).mount('#app')

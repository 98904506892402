<template>
  <div class="thanks">
      <div class="background"></div>
      <div class="thanks__main" role="main">
          <div class="thanks__logo onboarding__logo">
              <img src="/dist/img/logo-jo-anim-white.svg" alt="Logo des Volontaires des Jeux Olympiques et Paralympiques de Paris 2024"/>
          </div>
          <div class="thanks__content onboarding__content">
              <h1 class="uppercase">
                  {{ t("close.title") }}
              </h1>
              <p v-html="DOMPurify.sanitize(t('close.description'))"></p>  
          </div>
      </div>
  </div>
</template> 

<script>
import { onMounted, onBeforeMount, defineComponent } from "vue";
import { useI18n } from 'vue-i18n';
import DOMPurify from 'dompurify';


export default defineComponent({
name: "Close",
components: {},
props: {},
setup() {
  const { t } = useI18n({})

  onBeforeMount(() => {
      
      
  })

  onMounted(() => {    
      
  })

  return {
          t,
          DOMPurify,
      }
}
});
</script>
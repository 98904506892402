<template>
    <div class="thanks">
        <div class="background"></div>
        <div class="thanks__main" role="main">
            <div class="thanks__logo onboarding__logo">
                <img src="/dist/img/logo_jo_paris2024.gif" alt="Logo des Volontaires des Jeux Olympiques et Paralympiques de Paris 2024"/>
            </div>
            <div class="thanks__content onboarding__content">
                <h1 class="uppercase">
                    {{ t("send.title") }}
                </h1>
                <p v-html="DOMPurify.sanitize(t('send.description'))"></p>
                <p align="center" role="progressbar"><img src="/loading-pink.gif" alt="Loading" width="50" /></p>    
            </div>
        </div>
    </div>
</template> 

<script>
import { onMounted, onBeforeMount, defineComponent, ref } from "vue";
import axios from 'axios'
import axiosRetry from 'axios-retry';
import configApp from '../config/config'
import { useI18n } from 'vue-i18n';
import DOMPurify from 'dompurify'
import { useCandidateStore } from '@/stores/candidate'
import { useRouter } from 'vue-router';
import { removeLocalStorageHelper } from '@/helpers/localStorageHelper.js';
import { logErrorCustom } from '@/helpers/logCustomError.js';


export default defineComponent({
  name: "Thanks",
  components: {},
  props: {},
  setup() {
    const { t } = useI18n({})
    const router = useRouter();
    const candidate = useCandidateStore()
    const errorCall = ref(false)

    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()

    onBeforeMount(() => {
        let cguValidated = localStorage.getItem('af-parisjo-cgu');

        if(!cguValidated){
            router.push({ name: 'Home'})
        }

        const assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-shape');
        const assessmentDriveUser = localStorage.getItem('af-parisjo-assessment-drive');

        if(!assessmentShapeUser){
            router.push({ name: 'Assessments',params: { type: 'shape'}})
        }
        if(!assessmentDriveUser){
          router.push({ name: 'Assessments',params: { type: 'drive'}})
        }

        const adequacyScoreUser = localStorage.getItem('af-parisjo-userScore');
        const localUserChoices = localStorage.getItem('af-parisjo-mission-choice');
        if(!adequacyScoreUser || !localUserChoices){
          router.push({ name: 'Results' })
        }
        
    })

    onMounted(() => {    
        const localUserChoices = JSON.parse(localStorage.getItem('af-parisjo-mission-choice'))

        let finalDataToSend = {
            "userId": localStorage.getItem('af-parisjo-user'),
            "retry": 0,
            "date": day + "/" + month + "/" + year,
            "adequacies":{
                "global": {
                    "adequacy": candidate.adequacy.global.global
                },
                "values": {},
                "missions": {}

            }
        }

        /* ADD VALUES TO DATA RESULT */
        let valuesArray = []
        for(const [key, value] of Object.entries(candidate.adequacy.valeurs)){
            valuesArray.push({'category': key, 'adequacy': value})
        }
        finalDataToSend.adequacies.values=valuesArray

        /* ADD MISSIONS TO DATA RESULT */
        let missionsArray = []
        for(const [key, value] of Object.entries(candidate.adequacy.missions)){
            missionsArray.push({'category': key, 'adequacy': value, 'is_selected': (localUserChoices.indexOf(key)<0?false:true)})
        }
        finalDataToSend.adequacies.missions=missionsArray

        /* SEND TO DB */
        const dataToSend = {
            "uuid": localStorage.getItem('af-parisjo-user'),
            "choices": finalDataToSend,
        };

        const axiosConfig = {
            headers: { 
                'x-api-key': configApp.AWS_API_KEY
            }
        };

        axiosRetry(axios, { retries: 10, retryDelay: (retryCount) => {
                    return retryCount * 1000;
                    }});

        /* SAVE DATAS AND SEND TO ATOS */
        axios.post(configApp.AWS_BASE_URL+'choices',dataToSend, axiosConfig)
        .then(function (response) {
            //Remove Local storage and redirect
            removeLocalStorageHelper()
            localStorage.removeItem('af-parisjo-user-custom')
            localStorage.removeItem('af-parisjo-lang');
            
            if(configApp.LOAD_TEST == 0){
                // Redirect to Atos pPlatform
                window.location.href = configApp.ATOS_PLATFORM_URL+'/vportal/profile';
            }
            
                    
        })
        .catch(function (error) {
            errorCall.value = true
            logErrorCustom(localStorage.getItem('af-parisjo-user'), error, "Error API", "Send Choice");
            alert('Call API Error')
            console.log(error);
            window.location.href = configApp.ATOS_PLATFORM_URL+'/vportal/profile';
        });

    })

    return {
            t,
            DOMPurify,
        }
  }
});
</script>
<template>
  <Question class="question" v-if="questionElementObject" :number="questionNumber" :questionElement="questionElementObject" @onResponseProposal="handleResponseClick" />
</template>


<script>
import { computed, onMounted, defineComponent, ref, onBeforeMount } from "vue";
import { useRouter } from 'vue-router';
import Question from '../components/questions/Question.vue';
import { useI18n } from 'vue-i18n';

import assessmentModelFr from "../assets/assessments/shape/fr.json";
import assessmentModelEn from "../assets/assessments/shape/en.json";

export default defineComponent({
  name: "Shape",
  components: { Question },
  props: {},
  setup() {
    const locale = useI18n();
    const router = useRouter();
    
    const assessmentObjectList = computed(() => {
      if(locale.locale.value == 'fr'){
          return assessmentModelFr;
      }else{
          return assessmentModelEn;
      }
    });

    const questionNumber = ref(90);
    const currentQuestion = ref(1);
    const questionElementObject= ref(null);

    function loadSpecificQuestion(index){
      // check if shape is allready done
      let assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-shape');
      if(assessmentShapeUser){
        assessmentShapeUser = JSON.parse(assessmentShapeUser);
        if(Object.keys(assessmentShapeUser).length == 90){
          router.push({ name: 'Assessments',params: { type: 'continue'}})
        }
      }
      
      questionNumber.value=91-index;
      currentQuestion.value = index;
      questionElementObject.value = assessmentObjectList.value[currentQuestion.value];

    }

    function loadCurentQuestion(){
      questionNumber.value--;
      currentQuestion.value++;
      if(currentQuestion.value>90){
          router.push({ name: 'Assessments',params: { type: 'continue'}})
      }
      questionElementObject.value = assessmentObjectList.value[currentQuestion.value];
    }

    function handleResponseClick(itemId, proposalId, dimension) {
      let assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-shape');
      
      if(assessmentShapeUser){
        assessmentShapeUser =  JSON.parse(assessmentShapeUser);
      }else{
        assessmentShapeUser = {};
      }

      assessmentShapeUser[itemId] = { id: proposalId, dim: dimension };

      localStorage.setItem('af-parisjo-assessment-shape',JSON.stringify(assessmentShapeUser));

      loadCurentQuestion();
    }

    onMounted(() => {
        let assessmentShapeUser = localStorage.getItem('af-parisjo-assessment-shape');
        let lastIndex = 1;

        if(assessmentShapeUser){
            assessmentShapeUser =  JSON.parse(assessmentShapeUser);
            for(var i in assessmentShapeUser){
              lastIndex = i;
            }
            lastIndex++;
        }
        
        loadSpecificQuestion(lastIndex);
    })

    onBeforeMount(() => {
        let cguValidated = localStorage.getItem('af-parisjo-cgu');

        if(!cguValidated){
            router.push({ name: 'Home'})
        }
    })


    return {
      handleResponseClick,
      questionNumber,
      questionElementObject
    };
  }
});
</script>

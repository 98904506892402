<template>
  <div class="missions">
      <div class="background"></div>
      <div class="missions__main" role="main">
          <div class="thanks__logo onboarding__logo">
              <img src="/dist/img/logo-jo-anim-white.svg" alt="Logo des Volontaires des Jeux Olympiques et Paralympiques de Paris 2024"/>
          </div>
          <div class="missions__adequation" v-if="currentLang=='FR'" style="white-space:break-spaces;">
            <h1 class="uppercase">Conditions générales d'utilisation d'AssessFirst Candidats</h1><h2>1. Préambule</h2><p>AssessFirst propose un service de mise à disposition de questionnaires d’orientation (ci-après : les « Questionnaires ») permettant aux équipes de Paris 2024 d’évaluer l’adhésion aux valeurs et à la vision des Jeux, l’adéquation aux missions proposées, et aussi de recueillir les souhaits de missions des Candidats en vue de leur embauche en tant que bénévole, dans le cadre des Jeux Olympiques de Paris 2024 (ci-après ensemble : les « Candidats »).
              <br /><br />
Ce service est accessible à l’adresse suivante :&nbsp;<a href="">XXX</a> (ci-après «&nbsp;l’Application&nbsp;»).</p><h2>2. Identité d’AssessFirst</h2><p>Le service est exploité par AssessFirst, SAS, immatriculée au RCS de Paris sous le n° 443 179 684, dont le siège social est situé 10, Rue de la Paix, 75002 Paris, France (ci-après : « AssessFirst »).
<br /><br />
Il est hébergé par AWS, au sein de l’Union européenne.
<br /><br />
AssessFirst peut être contactée aux coordonnées suivantes :
<br /><br />
Adresse postale : 10, Rue de la Paix, 75002 Paris, France<br />
Adresse électronique : hello@assessfirst.com</p><h2>3. Prérequis</h2><p>Les Candidats déclarent avoir obtenu de la part d’AssessFirst toutes les informations nécessaires quant aux Services proposés et adhérent sans réserve aux présentes conditions générales d’utilisation.
  <br /><br />
Les Candidats reconnaissent que l’utilisation de l’Application nécessite le respect de l’ensemble des prescriptions définies au sein des présentes.
<br /><br />
Les Candidats peuvent bénéficier des services qui leur sont proposés sur l’Application sous réserve de respecter les prérequis suivants :
<br /><br />
- disposer d’un équipement informatique adapté pour accéder à l’Application ;<br />
- disposer d’une adresse de courrier électronique valable ;<br />
- être informé que leur engagement ne nécessite pas de signature manuscrite ou électronique ; l’acceptation des présentes conditions générales d’utilisation est mise en œuvre par le mécanisme d’une case à cocher.
<br /><br />
Les Candidats disposent de la faculté de sauvegarder et d’imprimer les présentes conditions générales en utilisant les fonctionnalités standards de leur navigateur ou de leur ordinateur.</p><h2>4. Définitions</h2><p>Les termes ci-dessous définis auront entre les parties la signification suivante :
  <br /><br />
- «&nbsp;Partie(s)&nbsp;»&nbsp;: désigne AssessFirst et le Candidat ensemble&nbsp;;<br />
- « Service » : service proposé par AssessFirst accessible en ligne sur l’Application ;<br />
- «&nbsp;Candidats&nbsp;»&nbsp;: les candidats sont entendus comme toute personne physique utilisant les services d’AssessFirst en vue de leur embauche en tant que bénévole par les équipes de Paris 2024.</p><h2>5. Objet</h2><p>Le présent document a pour objet de définir les modalités d’utilisation des Services et les droits et obligations respectifs des Candidats lors de l’utilisation des Services disponibles sur l’Application.</p><h2>6. Opposabilité</h2><p>Les Candidats ne peuvent bénéficier des services qui leur sont proposés sur l’Application que sous réserve de l’acceptation des présentes conditions générales.<br />
<br />
Les présentes conditions générales sont opposables dès leur acceptation par le Candidat.
<br /><br />
AssessFirst se réserve le droit d’apporter aux présentes conditions générales toutes les modifications qu’elle jugera nécessaires et utiles. Elle fera ses meilleurs efforts pour informer les Candidats de l’existence et de l’entrée en vigueur de nouvelles conditions générales par tous moyens à sa disposition (courriers électroniques, information en ligne, etc.).
<br /><br />
Les Candidats peuvent accéder aux conditions générales archivées en faisant la demande par courrier électronique à l’adresse :&nbsp;hello@assessfirst.com.
<br /><br />
Les conditions générales figurant en ligne sur l’Application prévalent sur toute version imprimée de date antérieure.<br />
Date de la dernière mise à jour : 8 novembre 2022
<br /><br />
Le Candidat peut à tout moment renoncer à utiliser le service mais reste responsable de toute utilisation antérieure.</p><h2>7. Durée</h2><p>Les conditions générales d’utilisation sont applicables pendant toute la durée d’utilisation du service par le Candidat.</p><h2>8. Accès au service</h2><p>L’accès au Questionnaire se fait en cliquant sur un bouton “Questionnaire d’orientation” dans le formulaire de candidature à l’adresse <a href="">.
  <br /><br />
Le Candidat est orienté vers l’Application.</a></p><h2>9. Présentation des services</h2><p>Le Candidat a accès aux Services qui suivent, sous une forme et selon les fonctionnalités et moyens techniques qu’AssessFirst juge les plus appropriés.
  <br /><br />
Au regard des réponses apportées par le Candidat, AssessFirst établit des rapports d’orientation (les «&nbsp;Rapports&nbsp;») qui retranscrivent notamment ses réponses.
<br /><br />
Les Rapports ne pourront être établis et remis aux équipes de Paris 2024 qu’après que le Candidat aura intégralement complété les Questionnaires.
<br /><br />
Les délais de remise des Rapports sont indiqués sur l’Application. AssessFirst n’offre en effet aucune garantie concernant les délais de remise des Rapports, dans la mesure où celle-ci est dépendante de la volonté du Candidat et qu’elle ne maîtrise pas la finalisation des Questionnaires par ce dernier.</p><h2>10. Obligation d’AssessFirst</h2><h3>10.1 Qualité du service</h3><p>AssessFirst s’efforce de fournir un service de qualité. Elle permet aux Candidats d'utiliser les moyens mis à leur disposition dans les meilleures conditions possibles.
  <br /><br />
En raison de la nature et de la complexité du réseau de l’internet, et en particulier, de ses performances techniques et des temps de réponse pour consulter, interroger ou transférer les données d’informations, AssessFirst fait ses meilleurs efforts, conformément aux règles de l’art, pour permettre l’accès et l’utilisation du service. AssessFirst ne saurait en effet assurer une accessibilité ou une disponibilité absolue de l’Application permettant l’accès au service.
<br /><br />
AssessFirst ne saurait être responsable du bon fonctionnement de l’équipement informatique du Candidat ainsi que de son accès à internet.</p><h3>10.2 Maintenance et mise à jour</h3><p>AssessFirst fait ses meilleurs efforts pour fournir un service performant aux Candidats. Elle s'engage à mettre en œuvre les moyens adaptés pour maintenir le service dans des conditions opérationnelles optimales.
  <br /><br />
AssessFirst se réserve le droit de fermer temporairement la plateforme de l’Application ou l’accès aux services notamment pour effectuer une mise à jour, des opérations de maintenance, des modifications ou changements sur les méthodes opérationnelles, la plateforme et les heures d’accessibilité.</p><h3>10.3 Sécurité</h3><p>AssessFirst fait ses meilleurs efforts, conformément aux règles de l’art, pour sécuriser l’Application.
  <br /><br />
Eu égard à la nature exponentielle des évolutions technologiques, AssessFirst ne saurait assurer une sécurité absolue de l’Application ou l’absence de faille.</p><h2>11. Obligation des Candidats</h2><h3>11.1 Utilisation conforme</h3><p>Le Candidat est responsable de l’utilisation du service et de ses actes sur l’Application.
  <br /><br />
Le Candidat s’engage à utiliser l’Application de façon loyale, dans le respect des présentes conditions générales en agissant de manière compatible avec l’Application et les législations et réglementations en vigueur.
<br /><br />
Le Candidat s'interdit tout comportement illégal ou constitutif d'une fraude à l'égard d’AssessFirst, des autres Candidats ou encore des tiers.
<br /><br />
Le Candidat s’interdit de collecter de quelque façon que ce soit des informations sur les autres Candidats, manuellement ou de manière automatisée, en particulier les adresses de courrier électronique, sans leur consentement, notamment en vue de l’envoi de prospections non sollicitées ou spams, de chaînes de courriers électroniques, etc.</p><h3>11.2 Sécurité</h3><p>La plateforme est un système de traitement automatisé de données.
  <br /><br />
Il est interdit au Candidat d'accéder ou de se maintenir, frauduleusement, dans tout ou partie de la plateforme. Il s'interdit d’utiliser une méthode d’accès autre que l’interface et les instructions mises à disposition par AssessFirst.
<br /><br />
Il est interdit au Candidat de supprimer ou modifier des données contenues sur la plateforme de l’Application ou d’y introduire frauduleusement des données ou même d’opérer une altération du fonctionnement de la plateforme. Il veille notamment à ne pas introduire de virus, code malveillant ou toute autre technologie nuisible à la plateforme ou aux services qui y sont proposés.
<br /><br />
Tout accès à un espace interdit sera considéré comme un accès frauduleux au sens des dispositions du Code pénal.
<br /><br />
Le Candidat s’engage à considérer que toutes les données dont il aura eu connaissance à l’occasion d’un tel accès à l’espace non autorisé sont des données confidentielles et s’engage, en conséquence, à ne pas les divulguer.
<br /><br />
Le Candidat s’interdit notamment de réaliser toute opération visant à saturer une page, opérations de rebond ou toute opération ayant pour conséquence d'entraver ou de fausser le fonctionnement de la plateforme.
<br /><br />
Le Candidat accepte les caractéristiques et limites de l’internet.
<br /><br />
Le Candidat a conscience que les données circulant sur l’internet ne sont pas nécessairement protégées, notamment contre les détournements éventuels.</p><h2>12. Assistance technique</h2><p>AssessFirst met à la disposition de ses Candidats un service client à même de répondre à tous les renseignements nécessaires à l’utilisation de l’Application.
  <br /><br />
AssessFirst fait ses meilleurs efforts pour fournir un service accessible à tous les Candidats dans les meilleures conditions.
<br /><br />
AssessFirst ne peut garantir une compatibilité technique absolue des fonctionnalités et services additionnels qu’elle propose dans le cadre du service puisque leur bon fonctionnement est soumis à la compatibilité logicielle et matérielle des équipements informatiques utilisés par les Candidats.
<br /><br />
Dans l’hypothèse où un Candidat rencontre des difficultés pour accéder et/ou utiliser l’un des services proposés par l’Application, il lui est offert à tout moment la possibilité de contacter le service client à l’aide du formulaire en ligne accessible en ligne sur l’Application.</p><h2>13. Propriété intellectuelle</h2><h3>13.1 Éléments du Service</h3><p>Les présentes conditions générales d’utilisation n’emportent aucune cession d’aucune sorte de droits de propriété intellectuelle sur les éléments appartenant à AssessFirst au bénéfice du Candidat.
  <br /><br />
Les logiciels et programmes, les algorithmes de calcul, le code source et tous les procédés et processus utilisés par et pour la construction et le déploiement de l’Application ou toute autre information ou support présentés par AssessFirst sans que cette liste ne soit exhaustive, sont la propriété exclusive d’AssessFirst et sont protégés par leurs droits d’auteurs, marque, brevet et tout autre droit de propriété intellectuelle ou industrielle qui leur sont reconnus selon les lois en vigueur.
<br /><br />
Toute reproduction et/ou représentation, totale ou partielle d’un de ces éléments, sans l’autorisation expresse d’AssessFirst est interdite et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
<br /><br />
En conséquence, le Candidat s'interdit tout agissement et tout acte susceptible de porter atteinte directement ou non aux droits de propriété intellectuelle d’AssessFirst.</p><h3>13.2 Éléments des tiers</h3><p>Les éléments appartenant à des tiers, telle que marques, dessins, modèles, images, textes, photos, logo sans que cette liste ne soit exhaustive, sont la propriété exclusive de leur auteur et sont protégés à ce titre par le droit d’auteur, le droit des marques ou tout autre droit reconnu par la législation en vigueur.
  <br /><br />
Le Candidat s'interdit de porter atteinte, directement ou indirectement, aux droits de propriété de tiers, dont les contenus sont présents sur l’Application et s'interdit d'exploiter, de quelque manière que ce soit, les noms, les marques, les logo, les logiciels, les informations, les bases de données et tous les documents qui lui sont communiqués, d'une manière générale, dans le cas de l'exécution des présentes conditions générales.
<br /><br />
Le Candidat s'engage à respecter l'intégralité des droits de tiers, dont les contenus sont présents sur l’Application et s'interdit de susciter toute analogie dans l'esprit du public à quelque fin que ce soit.
<br /><br />
À cet effet, le Candidat s'engage à prendre toutes les mesures nécessaires à la protection desdits droits à l'égard de tous tiers et, notamment, maintiendra en état toutes les mentions de propriété qui seront portées sur l'ensemble des données, informations et plus généralement sur les éléments consultables sur la plateforme ou rendus accessibles par des tiers.</p><h2>14. Responsabilité</h2><p>L’intervention d’AssessFirst se limite à la fourniture des Services présents sur l’Application.
  <br /><br />
Dans le cadre de la fourniture des Services, AssessFirst est soumise à une obligation de moyens.
<br /><br />
En aucun cas, les informations fournies par les Questionnaires et les Rapports ne sauraient être constitutives de recommandations 100% automatisées.
<br /><br />
La décision d’embauche appartient exclusivement aux équipes de Paris 2024 et les Services ne constituent que des outils d’aide à la décision et ne sauraient être considérés autrement.
<br /><br />
AssessFirst ne saurait donc être responsable de l’utilisation qui pourrait être faite de ces informations et des conséquences qui pourraient en découler, notamment au niveau des décisions qui pourraient être prises ou des actions qui pourraient être entreprises à partir de ces informations par le Candidat.
<br /><br />
AssessFirst n’est, par ailleurs, aucunement responsable des échanges ou des relations entre les équipes de Paris 2024 et les Candidats qui auraient lieu en dehors de l’Application.
<br /><br />
À ce titre, le Candidat demeure seul et unique responsable de l’usage des informations mises à sa disposition.
<br /><br />
Lorsque le Candidat entre en relation avec les équipes de Paris 2024, AssessFirst ne saurait être responsable de la relation intervenue et des éventuels contrats ou conventions convenus entre le Candidat et lesdites équipes.
<br /><br />
En tout état de cause, AssessFirst ne saurait garantir les informations fournies par le Rapport, celles-ci étant compilées à partir des éléments renseignés par le Candidat lui-même.
<br /><br />
Les résultats des Questionnaires dépendent en effet des informations fournies sur une base déclarative du candidat et AssessFirst ne saurait être responsable d’une erreur de toutes données de nature dimensionnelle ainsi que de tout oubli du Candidat.
<br /><br />
Ainsi, AssessFirst ne peut en aucun cas être responsable des conséquences d’un renseignement erroné ou encore incomplet.
<br /><br />
AssessFirst ne saurait être responsable de la violation des présentes conditions générales par d’autres Candidats, ni de leurs agissements sur l’Application, qu’ils soient ou non constitutifs d’une faute.</p><h2>15. Force majeure</h2><p>Dans un premier temps, les cas de force majeure suspendront l’exécution des conditions générales.
  <br /><br />
Si les cas de force majeure ont une durée d’existence supérieure à deux (2) mois, les présentes conditions générales seront résiliées automatiquement.
<br /><br />
De façon expresse, sont considérés comme cas de force majeure ou cas fortuits, ceux habituellement retenus par la jurisprudence des cours et tribunaux français.</p><h2>16. Bonne foi</h2><p>Les Parties conviennent d’exécuter leurs obligations avec une parfaite bonne foi.</p><h2>17. Sincérité</h2><p>Les Parties déclarent sincères les présents engagements.
  <br /><br />
À ce titre, elles déclarent ne disposer d’aucun élément à leur connaissance qui, s’il avait été communiqué, aurait modifié le consentement de l’autre partie.</p><h2>18. Nullité</h2><p>Si une ou plusieurs stipulations des présentes sont tenues pour non valides ou déclarées comme telles en application d’une loi, d’un règlement ou à la suite d’une décision passée en force de chose jugée d’une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.</p><h2>19. Intégralité</h2><p>Les présentes conditions de service expriment l’intégralité des obligations des Parties.</p><h2>20. Convention de preuve</h2><p>L’acceptation des conditions générales par voie électronique a, entre les Parties, la même valeur probante que l’accord sur support papier.
  <br /><br />
Les registres informatisés et conservés dans les systèmes informatiques de l’Application seront conservés dans des conditions raisonnables de sécurité et considérés comme les preuves des communications intervenus entre les parties.
<br /><br />
L’archivage des documents contractuels est effectué sur un support fiable et durable pouvant être produit à titre de preuve.</p><h2>21. Confidentialité</h2><p>Les informations transmises ou recueillies par AssessFirst à l’occasion de l’utilisation de l’Application sont considérées par nature comme confidentielles et relevant du secret professionnel et ne font l’objet d’aucune communication extérieure.
  <br /><br />
Cette disposition ne fait pas obstacle aux communications ordonnées par voie judiciaire ou administrative.</p></div>

<div class="missions__adequation" v-else><h1 class="uppercase">AssessFirst general Terms and Conditions of use for Candidates</h1><h2>1. Introduction</h2><p>AssessFirst offers a service providing career questionnaires (the “Questionnaires”), enabling the Paris 2024 staff to assess Candidates’ adherence to the values and vision of the Games, their suitability for the suggested missions, and also to compile a list of Candidates’ mission-related wishes, to hire them as volunteers for the Paris 2024 Olympic Games (collectively: the “Candidates”).
  <br /><br />
This service can be accessed at the following address:&nbsp;<a href="">XXX</a> (hereinafter “the Application”).</p><h2>2. Identity of AssessFirst</h2><p>The service is operated by AssessFirst, SAS, a company registered with the Paris Trade and Companies Registry [RCS] under no. 443 179 684, whose registered office is located at 10 Rue de la Paix, 75002 Paris, France (hereinafter: “AssessFirst”).
  <br /><br />
The service is hosted by AWS, within the European Union.
<br /><br />
AssessFirst’s contact details are as follows:
<br /><br />
Postal address: 10, Rue de la Paix, 75002 Paris, France<br />
Email address: hello@assessfirst.com</p><h2>3. Requirements</h2><p>Candidates declare that they have obtained all the information from AssessFirst regarding the Services offered and will implicitly comply with these general terms and conditions of use.
  <br /><br />
Candidates acknowledge that using the Application requires compliance with all of the requirements defined herein.
<br /><br />
Candidates may benefit from the services offered to them on the Application provided that they meet the following requirements:
<br /><br />
- to have the appropriate computer requirement to access the Application;<br />
- to have a valid email address;<br />
- to have been informed that a handwritten or electronic signature is not required for them to be bound by these general terms and conditions of use; acceptance of these general terms and conditions of use is triggered by a checkbox.
<br /><br />
Candidates can save and print out these general terms and conditions using the standard functionalities in their browser or on their computer.</p><h2>4. Definitions</h2><p>The terms defined below will have the following meaning between the parties:
  <br /><br />
- “Party(ies)”: collectively refers to AssessFirst and the Candidate;<br />
- “Service”: refers to the service offered by AssessFirst, which is accessible online on the Application;<br />
- “Candidates”: any natural persons using the services of AssessFirst for them to be hired as volunteers by the Paris 2024 staff teams.</p><h2>5. Purpose</h2><p>The purpose of this document is to define the terms of use of the Services and the Candidates’ respective rights and obligations when using the Services available in the Application.</p><h2>6. Enforceability</h2><p>Candidates may only benefit from the Services provided for them in the Application subject to their acceptance of these general terms and conditions of use.
  <br /><br />
These general terms and conditions come into effect as soon as they are accepted by the Candidate at the time of registering for the Service.
<br /><br />
AssessFirst reserves the right to make any changes to these general terms and conditions that it deems necessary and useful. It will make its best efforts to inform Candidates of the existence and entry into effect of any new general terms and conditions by any means at its disposal (emails, online information, etc.).
<br /><br />
Candidates may access the archived general terms and conditions by emailing their request to hello@assessfirst.com.
<br /><br />
The general terms and conditions appearing online on the Application prevail over any previous printed version.<br />
Date of last update: 8 November 2022
<br /><br />
The Candidate may cease to use the service at any time but remains liable for any previous use.</p><h2>7. Term</h2><p>The general terms and conditions of use are applicable for the entire duration the Candidate uses the service.</p><h2>8. Access to Service</h2><p>The questionnaire can be accessed by clicking the “Career questionnaire” button in the application form at the following address: <a href="">.
  <br /><br />
This takes the Candidate to the Application.</a></p><h2>9. Presentation of Services</h2><p>The Candidate has access to the following Services, in the format and using the technical functionalities and resources deemed most appropriate by AssessFirst.
  <br /><br />
Based on the Candidate’s responses, AssessFirst shall compile career reports (“Reports”) transcribing their responses, among other things.
<br /><br />
Reports may only be compiled and delivered to the Paris 2024 staff teams once the Candidate has fully filled out the Questionnaires.
<br /><br />
The delivery schedule for Reports is indicated on the Application. These delivery schedules are only indicative. AssessFirst does not make any guarantee regarding the delivery schedules of Reports, insofar as these schedules depend on the willingness of each Candidate to complete their Questionnaires, which is outside the control of AssessFirst.</p><h2>10. AssessFirst's obligations</h2><h3>10.1 Quality of service</h3><p>AssessFirst strives to provide quality service. It allows Users to avail of the means of communication provided for them in the best possible conditions.
  <br /><br />
Given the nature and complexity of the Internet network, and in particular, its technical performance and response times for consulting, querying or transferring data, AssessFirst makes its best efforts to enable access and use of the Service, in accordance with trade practices. In this regard, AssessFirst cannot guarantee constant accessibility or availability of the Application that enables access to the Service.
<br /><br />
AssessFirst cannot be held responsible for the proper functioning of the Candidates' computer equipment or their access to the Internet.</p><h3>10.2 Maintenance and updates</h3><p>AssessFirst makes reasonable effort to provide Candidates with an effective service. It undertakes to implement appropriate resources to keep the Service operating optimally.
  <br /><br />
AssessFirst reserves the right to temporarily close the Application’s platform or to withdraw access to the Services, especially in order to perform updates or maintenance operations, or to make changes to operating methods, the platform and accessibility times.</p><h3>10.3 Security</h3><p>AssessFirst shall make every effort to keep the Application secure, in accordance with industry standards.
  <br /><br />
However, given the exponential nature of technological changes, AssessFirst is unable to guarantee the absolute security of the Application or the absence of any vulnerabilities.</p><h2>11. Candidates’ obligations</h2><h3>11.1 Intended use</h3><p>The Candidate shall be responsible for the way they use the Service and for what they do within the Application.
  <br /><br />
The Candidate shall use the Application fairly, in accordance with these general terms and conditions, acting in a way that is compatible with the Application and with current legislation and regulations.
<br /><br />
The Candidate shall refrain from engaging in any illegal or fraudulent behaviour towards AssessFirst, any other Candidates or third parties.
<br /><br />
The Candidate shall refrain from gathering information on other Candidates in any way, whether manually or using automated means, and especially email addresses, without their consent, with a view to sending unsolicited commercial communications or spam messages, email chains, etc.</p><h3>11.2 Security</h3><p>The platform is an automated data-processing system.
  <br /><br />
The Candidate is prohibited from fraudulently accessing or remaining on the platform, or any part of it. They shall refrain from using any methods of access other than the interface and instructions provided by AssessFirst.
<br /><br />
The Candidate is prohibited from deleting or altering any of the data contained on the Application platform, from fraudulently introducing any data onto it, and doing anything that may alter the platform’s operation. In particular, they shall take care to not introduce any viruses, malicious code or any other technologies harmful to the platform or the Services offered on it.
<br /><br />
Gaining access to a prohibited area shall be deemed fraudulent access within the meaning of the French Criminal Code.
<br /><br />
The Candidate accepts that all data that they would have become aware of during such access to an unauthorised space is confidential data and undertakes, therefore, not to disclose it.
<br /><br />
In particular, the Candidate shall refrain from performing any operations that are intended to overload a page; any bounce operations; or any operations that hamper or distort the platform’s operation.
<br /><br />
The Candidate shall accept the characteristics and limits of the Internet.
<br /><br />
The Candidate is aware that data circulating on the Internet may not be protected, in particular against potential misappropriations.</p><h2>12. Technical support</h2><p>AssessFirst shall make a customer service function available to its Candidates, which can provide all of the information needed to use the Application.
  <br /><br />
AssessFirst makes its best efforts to provide a Service that is accessible to all Candidates under optimal conditions.
<br /><br />
AssessFirst cannot guarantee absolute technical compatibility of any extra functionalities and services it offers within the framework of the Service, since their capacity to function properly is dependent on the software and hardware compatibility of the IT equipment used by Candidates.
<br /><br />
If a Candidate encounters problems in accessing and/or using any of the Services offered by the Application, they can contact the customer service function at any time, using the online form accessible within the Application.</p><h2>13. Intellectual property</h2><h3>13.1 Items comprising the Service</h3><p>These general terms and conditions of use do not imply any transfer of intellectual property rights of any kind over items belonging to AssessFirst in the Candidate’s favour.
  <br /><br />
Items including but not limited to software and programs, calculation algorithms, source code, and all processes used by and in the Application’s construction and rollout, and any other information or medium presented by AssessFirst, are owned exclusively by AssessFirst and protected by copyright, trademarks, patents, and any other intellectual or industrial property rights recognised over them, under current legislation.
<br /><br />
Any reproduction and/or public display of any of these items, in whole or in part, without the express permission of AssessFirst is prohibited, and will constitute an infringement of the above rights, punishable by Articles L. 335-2 et seq. of the French Intellectual Property Code.
<br /><br />
The Candidate shall therefore refrain from any misconduct or acts that may infringe AssessFirst’s intellectual property rights, whether directly or indirectly.</p><h3>13.2 Elements owned by third parties</h3><p>Items belonging to third parties, including but not limited to trademarks, drawings, designs, images, texts, photos and logos, are owned exclusively by the party who created them and are protected in this regard by copyright, trademark law, and any other rights recognised by current legislation.
  <br /><br />
The Candidate shall refrain from infringing, either directly or indirectly, any property rights enjoyed by third parties whose content appears in the Application, and from exploiting, in any way whatsoever, any names, trademarks, logos, software programs, information, databases, and documents communicated to them, in general terms, if these general terms and conditions are executed.
<br /><br />
The Candidate shall respect all rights enjoyed by third parties whose content appears in the Application and shall refrain from creating any analogy in the public’s mind, for whatever purpose.
<br /><br />
To this end, the Candidate shall take all necessary measures to protect said rights against all third parties, and in particular, shall not make changes to any mentions of ownership appearing on all data and information, and more generally on any items that can be consulted on the platform or accessed by third parties.</p><h2>14. Liability</h2><p>AssessFirst’s intervention is limited to providing the Services present in the Application.
  <br /><br />
AssessFirst shall do its reasonable best efforts to provide the Services.
<br /><br />
The information provided by Questionnaires and Reports shall not under any circumstances be deemed to constitute 100% automated individual decision.
<br /><br />
The decision to hire someone shall be made solely by the Paris 2024 staff teams; the Services provided merely constitute decision-support tools and cannot be considered otherwise.
<br /><br />
Accordingly, AssessFirst shall not be liable for any use made of this information and for any consequences arising therefrom, notably in terms of decisions or actions taken by the Candidate on the basis of this information.
<br /><br />
Furthermore, AssessFirst shall not be liable for exchanges or relations between Paris 2024 staff teams and Candidates, occurring outside the framework of the Application.
<br /><br />
In this regard, Candidates shall remain solely responsible for the use made of the information provided to them.
<br /><br />
Where the Candidate enters into a communication with Paris 2024 staff teams, AssessFirst shall not be liable for the resulting relationship or for any contracts or agreements reached between the Candidate and said staff teams.
<br /><br />
AssessFirst cannot under any circumstances guarantee the veracity of the information provided by the Report, as it has been compiled from information entered by the Candidate themselves.
<br /><br />
Questionnaire results are in fact dependent on the information declared by the Candidate, and AssessFirst shall not be liable for any error in any data provided or for any omissions on the part of the Candidate.
<br /><br />
AssessFirst shall not be liable for the consequences of any incorrect or incomplete information provided.
<br /><br />
AssessFirst shall not be liable for any breaches of these general terms and conditions by other Candidates, nor for any misconduct on their part within the Application, whether this constitutes a breach or not.</p><h2>15. Force majeure</h2><p>Initially, any case of <i>force majeure</i> will suspend the execution of these general terms and conditions.
  <br /><br />
If a case of <i>force majeure</i> lasts longer than (2) months, these general terms and conditions shall be terminated automatically.
<br /><br />
Events normally interpreted by the case law of the French courts shall expressly be deemed to constitute cases of <i>force majeure</i> or fortuitous events.</p><h2>16. Good faith</h2><p>The Parties agree to perform their obligations in absolute good faith.</p><h2>17. Truthfulness</h2><p>The Parties declare that these undertakings are truthful.
  <br /><br />
They therefore declare that they are not in possession of any information which, had it been made known, would have changed the consent given by other Party.</p><h2>18. Invalidity</h2><p>If one or more of the stipulations contained herein are held to be invalid or declared as such, under a law or regulation, or consequent to a final decision handed down by a competent court, the other stipulations shall retain their full force and scope.</p><h2>19. Entire agreement</h2><p>These terms and conditions of service express the Parties’ obligations in their entirety.</p><h2>20. Agreement on evidence</h2><p>Acceptance of the general terms and conditions in electronic format shall have the same probative value between the Parties as the agreement formalised on paper.
  <br /><br />
Computerised registers kept on the Application’s IT systems shall be held under reasonable security conditions and deemed to constitute proof of communications taking place between the Parties.
<br /><br />
Contractual documents shall be archived on a reliable and permanent medium that can be produced as proof.</p><h2>21. Confidentiality</h2><p>Any information transmitted or gathered by AssessFirst in connection with use of the Application shall be deemed confidential in nature and subject to professional secrecy and shall not be communicated externally in any way.
  <br /><br />
This provision shall not prevent the communication of any information ordered by judicial or administrative authorities.</p></div>
<br /><br />

<!--
            <div class="missions__adequation">
            <h1 class="uppercase">
                {{ t("terms.page.title") }}
            </h1>

            <h2>{{ t('terms.page.section_1.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_1.body'))"></p>  

            <h2>{{ t('terms.page.section_2.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_2.body'))"></p>  

            <h2>{{ t('terms.page.section_3.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_3.body'))"></p>  

            <h2>{{ t('terms.page.section_4.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_4.body'))"></p>  

            <h2>{{ t('terms.page.section_5.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_5.body'))"></p>  

            <h2>{{ t('terms.page.section_6.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_6.body'))"></p>  

            <h2>{{ t('terms.page.section_7.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_7.body'))"></p>  

            <h2>{{ t('terms.page.section_8.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_8.body'))"></p>  

            <h2>{{ t('terms.page.section_9.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_9.body'))"></p>  

            <h2>{{ t('terms.page.section_10.title') }}</h2>
            <h3>{{ t('terms.page.section_10.subsection_1.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_10.subsection_1.body'))"></p>  
            <h3>{{ t('terms.page.section_10.subsection_2.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_10.subsection_2.body'))"></p>  
            <h3>{{ t('terms.page.section_10.subsection_3.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_10.subsection_3.body'))"></p>  

            <h2>{{ t('terms.page.section_11.title') }}</h2>
            <h3>{{ t('terms.page.section_11.subsection_1.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_11.subsection_1.body'))"></p>  
            <h3>{{ t('terms.page.section_11.subsection_2.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_11.subsection_2.body'))"></p>  

            <h2>{{ t('terms.page.section_12.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_12.body'))"></p>  

            <h2>{{ t('terms.page.section_13.title') }}</h2>
            <h3>{{ t('terms.page.section_13.subsection_1.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_13.subsection_1.body'))"></p>  
            <h3>{{ t('terms.page.section_13.subsection_2.title') }}</h3>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_13.subsection_2.body'))"></p>  

            <h2>{{ t('terms.page.section_14.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_14.body'))"></p>  

            <h2>{{ t('terms.page.section_15.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_15.body'))"></p>  

            <h2>{{ t('terms.page.section_16.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_16.body'))"></p>  

            <h2>{{ t('terms.page.section_17.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_17.body'))"></p>  

            <h2>{{ t('terms.page.section_18.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_18.body'))"></p>  

            <h2>{{ t('terms.page.section_19.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_19.body'))"></p>  

            <h2>{{ t('terms.page.section_20.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_20.body'))"></p>  

            <h2>{{ t('terms.page.section_21.title') }}</h2>
            <p v-html="DOMPurify.sanitize(t('terms.page.section_21.body'))"></p>  

          </div>
        -->
      </div>
  </div>
</template> 

<script>
import { onMounted, onBeforeMount, defineComponent } from "vue";
import { useI18n } from 'vue-i18n';
import DOMPurify from 'dompurify';


export default defineComponent({
name: "Cgu",
components: {},
props: {},
setup() {
  const { t } = useI18n({})
  const currentLang = localStorage.getItem('af-parisjo-lang')
  onBeforeMount(() => {
      
      
  })

  onMounted(() => {    
      
  })

  return {
          t,
          DOMPurify,
          currentLang
      }
}
});
</script>
<template>  
  <router-view />
</template>


<script>
import { defineComponent } from "vue";
import { useI18n } from 'vue-i18n';
import configApp from './config/config'



export default defineComponent({
  name: "App",
  components: {},
  props: {},
  setup() {
    const { t } = useI18n({})

    window.document.title = t('title')
    document.head.querySelector('meta[name="description"]').content = t('meta')

    return {
            t
        }
        
  }
})
</script>

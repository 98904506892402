import axios from 'axios'
import configApp from '../config/config'

const axiosConfig = {
    headers: { 
        'x-api-key': configApp.AWS_API_KEY
    }
};

export async function logErrorCustom(userId, error, errorType, errorFeature) {
    const dataToSend = {
        "uuid": userId+'_'+Math.floor(Date.now()/1000),
        "errorType": errorType,
        "errorFeature": errorFeature,
        "errorContent": error.toJSON()
      };
      //axiosRetry(axios, { retries: 1 });
      axios.post(configApp.AWS_BASE_URL+'tools/store_log', dataToSend, axiosConfig)
      .then(function (response) {
        return true
      }).catch(function (error) {
        
    });
}

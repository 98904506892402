<template>
    <div class="question-example">
        <QuestionExemple v-if="assessmentType == ''" />
        <QuestionContinue v-else-if="assessmentType == 'continue'" />
        <Shape v-else-if="assessmentType == 'shape'" />
        <Drive v-else-if="assessmentType == 'drive'" />
    </div>
</template>

<script>
import { computed, onBeforeMount } from "vue";
import { useRouter, useRoute } from 'vue-router';
import QuestionExemple from "../components/questions/QuestionExemple.vue";
import QuestionContinue from "../components/questions/QuestionContinue.vue";
import Shape from './Shape.vue';
import Drive from './Drive.vue';

export default {
  name: "Assessment",
  components: { QuestionExemple, QuestionContinue, Shape, Drive },
  setup() {
    const router = useRouter();
    const route = useRoute();

    // @todo => Check if assessment are already done
    const assessmentType = computed(() => route.params.type);
    const assessmentQuestion = computed(() => route.params.question);

    onBeforeMount(() => {
        let cguValidated = localStorage.getItem('af-parisjo-cgu');

        if(!cguValidated){
            router.push({ name: 'Home'})
        }
    })

    return {
      assessmentType,
      assessmentQuestion
    };
  }
};
</script>

<template>
    <div class="onboarding">
        <main>
            <div class="onboarding__container" style="height: auto !important;">
                <div role="main">
                    <div class="content-selector">
                        <LanguageSelector/>
                    </div>
                    <div class="onboarding__logo">
                        <img src="/dist/img/logo_jo_paris2024.gif" alt="Logo des Volontaires des Jeux Olympiques et Paralympiques de Paris 2024"/>
                    </div>
                    <div class="onboarding__content">
                        <h1 class="uppercase">
                            {{ t("landing.title") }}
                        </h1>
                        <p v-html="DOMPurify.sanitize(t('landing.description'))"></p>
                        <div class="checkbox">
                            <label class="checkbox__label" for="checkbox-default">
                                {{ t("landing.agreement_1") }} <a class="text-underline blue" :href="`/terms-of-use?lang=${currentLang}`" target="_blank">{{ t("landing.agreement_2") }}</a> {{ t("landing.agreement_3") }}
                            </label>
                            <input class="checkbox__topping" id="checkbox-default" type="checkbox" v-model="inputValue" value="1" @change="handleChangeInputState($event)">
                        </div>
                        <ButtonContinue class="link__container" :title="t('landing.continue')" :description="t('landing.alt_continue')" @onClick="handleContinueClick" :disabled="buttonDisable" />
                    </div>
                </div>
            </div>
        </main>
    </div>
</template> 

<script>
import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
import ButtonContinue from "../components/buttons/Button-Continue.vue";
import { useI18n } from 'vue-i18n'
import DOMPurify from 'dompurify'
import configApp from '../config/config'
import { removeLocalStorageHelper } from '@/helpers/localStorageHelper.js';
import LanguageSelector from "../components/langage-selector/LanguageSelector.vue";


export default {
    name: "Home",
    components: { ButtonContinue, LanguageSelector },
    setup() { 
        const router = useRouter();
        const inputValue = ref(false);
        const buttonDisable = ref(true)
        const { t } = useI18n({});

        const currentLang = localStorage.getItem('af-parisjo-lang').toUpperCase()

        //window.document.title = t('title')
        window.document.title = "Introduction - Questionnaire d'orientation - JOP24 Programme des volontaires"
        if(localStorage.getItem('af-parisjo-user-custom') == ""){
            removeLocalStorageHelper()
            window.location.href = configApp.ATOS_PLATFORM_URL;
        }
        if(localStorage.getItem('af-parisjo-user') != localStorage.getItem('af-parisjo-user-custom')){
                    
            removeLocalStorageHelper()
            window.location.href = configApp.ATOS_PLATFORM_URL;

            //router.push({ name: 'Login' })

        }

         /* ADD GIGYA TAG 
        let gigyaScript = document.createElement('script')
        gigyaScript.setAttribute('src', 'https://cdns.gigya.com/js/gigya.js?apiKey='+configApp.GIGYA_KEY)
        document.head.appendChild(gigyaScript)
        
        const gigyaParams = {
            includeAllIdentities: false,
            cid: Math.floor(Date.now() / 1000),
            callback: showAccInfo,
            context: {},
            extraFields: ''
        };

        gigyaScript.addEventListener('load', () => { window.gigya.socialize.getUserInfo(gigyaParams); })

        // gigya get current user
        function showAccInfo(responseGigya) {
            // 1 - Check Gigya logged user
            if(responseGigya.user.UID === ""){
               
                removeLocalStorageHelper()

                window.location.href = configApp.ATOS_PLATFORM_URL;
            }else{
                // compare local user and gigya UUID
                if(localStorage.getItem('af-parisjo-user') != responseGigya.user.UID){
                    
                    removeLocalStorageHelper()

                    router.push({ name: 'Login' })

                }else{
                    let cguUser = localStorage.getItem('af-parisjo-cgu');
                        
                    if(cguUser == "true"){
                        router.push({ name: 'Assessments',params: { type: ''}})
                    }
                }
            }
        }
*/
        function handleContinueClick() {  
            if(inputValue.value == true){
                // Save cgu to local storage
                localStorage.setItem('af-parisjo-cgu', true)
                
                // Redirect to next step
                router.push({ name: 'Assessments',params: { type: ''}})
            }
        }

        function handleChangeInputState(event){
            if(inputValue.value == true){
                buttonDisable.value = false
            }else{
                buttonDisable.value = true
            }
        }

        onMounted(() => {
                        
        })

        return {
            handleContinueClick,
            inputValue,
            buttonDisable,
            handleChangeInputState,
            t,
            DOMPurify,
            currentLang
        }
    }

};
</script>

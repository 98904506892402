import { defineStore } from 'pinia'

export const useCandidateStore = defineStore('candidate', {
  state: () => ({
      adequacy: {},
      choices: [],
  }),
  getters: {
    getCandidateMission(state) {
        const sortable = Object.fromEntries(
            Object.entries(state.adequacy.missions).sort(([,a],[,b]) => a-b)
        );
        let candidateMissions = []
        for(const [key, value] of Object.entries(sortable)){
            candidateMissions.push([key, value])
        }
        
        candidateMissions.reverse()

        return candidateMissions
    },
    getChoices(state) {
        return this.choices
    },
    getGlobalScore(state){
        return this.adequacy
    }
  },
  actions: {
    addAdequacy(adequacyElement) {
        // you can directly mutate the state
        this.adequacy = adequacyElement
    },  
    addChoice(choiceElement) {   
        const existingItem = this.choices.indexOf(choiceElement)
        let localUserChoices = localStorage.getItem('af-parisjo-mission-choice');

        if(localUserChoices){
            localUserChoices = JSON.parse(localUserChoices);
        }else{
            localUserChoices = []
        }
        if (existingItem === -1) {
            this.choices.push(choiceElement)
            localStorage.setItem('af-parisjo-mission-choice',JSON.stringify(this.choices));
        } 
    },  
    removeChoice(choiceElement) {
        const existingItem = this.choices.indexOf(choiceElement)
        if (existingItem !== -1) {
            this.choices.splice(existingItem, 1)
            localStorage.setItem('af-parisjo-mission-choice',JSON.stringify(this.choices));
        } 
    }
  },
})

<template>
    <main class="question__main" role="main">
        <HeaderBanner/>
        <div class="reminder">
            <div class="tooltip-reminder" v-on:mouseleave.stop="handleMouseLeavePopin">
                <button aria-describedby="info-reminder" @click="handleClicInfo" aria-label="Conseil pour répondre au questionnaire">
                    <span>{{ t("test.reminder.title") }}</span>
                    <img src="/dist/img/blue-tooltip.png" alt="Info"/>
                </button>
                <div class="tooltip-reminder__content" role="tooltip" id="info-reminder" v-if="showInfos">
                    <p v-html="DOMPurify.sanitize(t('test.reminder.description', {
                up_key: upKey,
                down_key: downKey,
            }))"></p>
                </div>
            </div>
        </div>
        <div class="question__container">
            <div class="grey-line number-container">
                    <div class="number-steps" role="heading" aria-level="1">
                        {{ number }}
                    </div>
                    <div class="border-hide"></div>
                </div>
                <div class="question__content">
                    <h2 class="bold" id="question">{{ questionElement.item}}</h2>
                    <div aria-labelledby="question" role="group" class="list" :data-item="questionElement.item" :data-itemid="questionElement.item_id">
                        <!-- aria-pressed="true" : activate button-->
                        <!-- aria-pressed="false" : disabled button-->
                        <button class="btn-question toggle-btn" id="response1" aria-pressed="false" :key="questionElement.proposals[0].proposal_id" type="button" :data-proposal="questionElement.proposals[0].proposal_id" @click.stop.prevent="handleClick(questionElement.item_id, questionElement.proposals[0].proposal_id, questionElement.proposals[0].dimension)">{{ questionElement.proposals[0].proposal }}</button>
                        <button class="btn-question toggle-btn" id="response2" aria-pressed="false" :key="questionElement.proposals[1].proposal_id" type="button" :data-proposal="questionElement.proposals[1].proposal_id" @click.stop.prevent="handleClick(questionElement.item_id, questionElement.proposals[1].proposal_id, questionElement.proposals[1].dimension)">{{ questionElement.proposals[1].proposal }}</button>
                    </div>
                </div>
        </div>
    </main>
</template>


<script>
import HeaderBanner from "../header/Header-Banner.vue";
import { ref, toRefs, defineComponent, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import DOMPurify from 'dompurify'

export default defineComponent({
    name: "Question",
    props: {
        number: {
            type: Number,
            required: true
        },
        questionElement: {
            type: Object,
            required: true
        }
    },
    //props: ['number', 'questionElement'],
    components: {HeaderBanner},
    setup(props, {emit}) {
        const { number, questionElement } = toRefs(props)
        const showInfos = ref(false)
        const { t } = useI18n({})
        const upKey = '<span class="ico-arrow-up"></span>'
        const downKey = '<span class="ico-arrow-down"></span>'


        removeListennerCustom();

        onMounted(() => {
            document.addEventListener('keyup', eventHandler);
        });
        onBeforeUnmount(() => {
            removeListennerCustom()
        });


        function eventHandler(event) {
            if(event.key === "ArrowUp"){
                removeListennerCustom();
                setTimeout(() => { 
                    document.getElementById('response1').classList.add("active");
                    setTimeout(() => { 
                    document.getElementById('response1').click(); 
                    document.getElementById('response1').classList.remove("active");
                    document.addEventListener('keyup', eventHandler);
                    }, 200);
                }, 400);                  
                
            }
            if(event.key === "ArrowDown"){
                removeListennerCustom();
                setTimeout(() => { 
                    document.getElementById('response2').classList.add("active");
                    setTimeout(() => { 
                    document.getElementById('response2').click(); 
                    document.getElementById('response2').classList.remove("active");
                    document.addEventListener('keyup', eventHandler);
                    }, 200);

                }, 400);   
                
            }
        }
        function removeListennerCustom(){
            document.removeEventListener('keyup',eventHandler)
        }

        function handleClick(itemId, proposalId, dimension) {
            emit('onResponseProposal',itemId, proposalId, dimension) 
        }

        function handleClicInfo(){
            showInfos.value = !showInfos.value 
        }
        function handleMouseLeavePopin(){
            showInfos.value = false
        }

        return {
            handleClicInfo,
            t,
            handleMouseLeavePopin,
            showInfos,
            handleClick,
            DOMPurify,
            upKey,
            downKey
        }

    }
});
</script>

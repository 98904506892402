<template>
  <div class="missions">
        <div class="background"></div>
        <main class="missions__main" role="main">
            <div class="missions__logo">
                <img src="/dist/img/logo_jo_paris2024.gif" alt="Logo des Volontaires des Jeux Olympiques et Paralympiques de Paris 2024"/>
            </div>
            <div class="missions__adequation" style="min-height: 62rem;">
              <p align="center" role="progressbar" v-if="loadingState"><img src="/loading-pink.gif" alt="Loading" width="50" /></p>
            </div>
        </main>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import axios from 'axios'
import configApp from '../config/config'
import { useCandidateStore } from '@/stores/candidate'
import { removeLocalStorageHelper } from '@/helpers/localStorageHelper.js';
import { logErrorCustom } from '@/helpers/logCustomError.js';



export default {
  name: "Login",
  setup() {
    const router = useRouter();
    const candidate = useCandidateStore()
    const loadingState = ref(true)

    let timeoutID;


    const axiosConfig = {
          headers: { 
              'x-api-key': configApp.AWS_API_KEY
          }
      };


    
    if(configApp.LOAD_TEST == 1){

      const urlParams = new URLSearchParams(window.location.search);

      let customTime = Date.now()

      removeLocalStorageHelper()

      localStorage.setItem('af-parisjo-user', urlParams.get('id')+'-'+customTime);

      router.push({ name: 'Home',params: { type: ''}})
      
    }else{
       /* ADD GIGYA TAG */
      let gigyaScript = document.createElement('script')
      gigyaScript.setAttribute('src', 'https://cdns.gigya.com/js/gigya.js?apiKey='+configApp.GIGYA_KEY)
      document.head.appendChild(gigyaScript)
      
      const gigyaParams = {
        includeAllIdentities: false,
        cid: Math.floor(Date.now() / 1000),
        callback: showAccInfo,
        context: {},
        extraFields: ''
      };

      gigyaScript.addEventListener('load', () => { window.gigya.socialize.getUserInfo(gigyaParams); })
      //timeoutID = setTimeout(forceUser, 60 * 1000);

    }

    function forceUser(){
      removeLocalStorageHelper()
      logErrorCustom(localStorage.getItem('af-parisjo-user-custom'), "", "Gigya", "Gigya SDK Error");

      localStorage.setItem('af-parisjo-user', localStorage.getItem('af-parisjo-user-custom'));

      router.push({ name: 'Home'})
    }
   
    // gigya get current user
    function showAccInfo(responseGigya) {
      //clearTimeout(timeoutID);

/*
      console.info("Gygia UUID : "+responseGigya.user.UID)
      console.warn("Gygia UUID : "+responseGigya.user.UID)
      console.error("Gygia UUID : "+responseGigya.user.UID)
      */
      // 1 - Check Gigya logged user
      if(responseGigya.user.UID === ""){
        removeLocalStorageHelper()

        localStorage.setItem('af-parisjo-user', localStorage.getItem('af-parisjo-user-custom'));

        router.push({ name: 'Home'})
        
        //window.location.href = configApp.ATOS_PLATFORM_URL;
      }else{
        if(configApp.ATOS_ENABLE == 0){
            // new user
            removeLocalStorageHelper()

            localStorage.setItem('af-parisjo-user', responseGigya.user.UID);

            router.push({ name: 'Home'})
        }else{
          // 2 - Check local user exist
          axios.get(configApp.AWS_BASE_URL+responseGigya.user.UID, axiosConfig)
          .then(function (response) {
            let userDatas = response.data;

            if(Object.keys(userDatas).length == 0){
              // user not exist in local DB

              //console.log('User not exist in local db')
              if(configApp.ATOS_ENABLE == 0){
                // new user
                removeLocalStorageHelper()

                localStorage.setItem('af-parisjo-user', responseGigya.user.UID);

                router.push({ name: 'Home'})
              }else{
                // 3 - Check if user exist in ATOS Platform
                let atosUserExist = false;
                const dataToSend = {
                  "requestType": "checkUser",
                  "userUid": responseGigya.user.UID
                };
                //axiosRetry(axios, { retries: 1 });
                axios.post(configApp.AWS_BASE_URL+'sendDatas', dataToSend, axiosConfig)
                .then(function (response) {
                  if(response.data.message == "true"){
                    // 4 - user exist in ATOS Platform BUT not in local DB => create a new user

                    //console.log('User OK From Atos')
                    // new user
                    removeLocalStorageHelper()

                    localStorage.setItem('af-parisjo-user', responseGigya.user.UID);
                    
                    router.push({ name: 'Home'})

                  }else{

                    //console.log('User KO From Atos')
                    // User not exist in local DB AND in Atos DB => redirect to platform
                    removeLocalStorageHelper()
                    
                    window.location.href = configApp.ATOS_PLATFORM_URL;
                  }
                })
                .catch(function (error) {
                  removeLocalStorageHelper()

                  localStorage.setItem('af-parisjo-user', responseGigya.user.UID);

                  logErrorCustom(responseGigya.user.UID, error, "TimeOut", "Check Atos User");

                  router.push({ name: 'Home'})
                  /*
                  const dataToSend = {
                    "uuid": responseGigya.user.UID,
                    "errorType": "TimeOut",
                    "errorFeature": "Check Atos User",
                    "errorContent": error.toJSON()
                  };
                  //axiosRetry(axios, { retries: 1 });
                  axios.post(configApp.AWS_BASE_URL+'tools/store_log', dataToSend, axiosConfig)
                  .then(function (response) {
                    router.push({ name: 'Home'})
                  })
                  */
                  
                });

              }
              

            }else{
              // user exist in local DB
              let localMissionChoice = []

                if(userDatas.choice != undefined && userDatas.choice.choices != undefined && userDatas.choice.choices.adequacies != undefined && userDatas.choice.choices.adequacies.missions != undefined){
                    userDatas.choice.choices.adequacies.missions.forEach(function(element){
                        if(element.is_selected === true){
                          localMissionChoice.push(element.category)
                        }
                      });
                    
                  localStorage.setItem('af-parisjo-mission-choice', JSON.stringify(localMissionChoice))
                }

                localStorage.setItem('af-parisjo-user', responseGigya.user.UID);
                localStorage.setItem('af-parisjo-cgu', true);
                localStorage.setItem('af-parisjo-assessment-shape', JSON.stringify(userDatas.answer.shape))
                localStorage.setItem('af-parisjo-assessment-drive', JSON.stringify(userDatas.answer.drive))
                localStorage.setItem('af-parisjo-userScore', JSON.stringify(userDatas.answer.adequacyScore))

                router.push({ name: 'Home' })
                
            }

          })
          .catch(function (error) {
            removeLocalStorageHelper()
            logErrorCustom(localStorage.getItem('af-parisjo-user-custom'), "", "TimeOut", "Check Local DB User");
            localStorage.setItem('af-parisjo-user', localStorage.getItem('af-parisjo-user-custom'));

            router.push({ name: 'Home'})
              /*
              console.log(error);
              window.location.href = configApp.ATOS_PLATFORM_URL;
              */
          });
        }
        
      }
    }

    return {
      loadingState
    };
  },
  
};
</script>

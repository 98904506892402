<template>
    <li v-if="mission != 'orientation_access' && mission != 'communication' && mission != 'collective_assistance' && mission != 'public' && mission != 'quality'">
        <div class="accordion__header" @click="handleShowDetail">
            <!---Begin of switcher button-->
            <div class="switcher">
                <h4 class="uppercase switcher__title">
                    {{ t("results.roles."+mission+".title") }}
                </h4>
                <button type="button" role="switch" :aria-checked="missionSelected" class="switcher__btn " @click.stop="handleModifyChoice" >
                    <svg aria-hidden="true">
                        <rect class="container" width="43" height="18" x="4" y="6" ry="50" rx="50" fill="#003F5C"></rect>
                        <rect class="off" width="18" height="18" x="4" y="3" ry="50" rx="50"></rect>
                        <rect class="on" width="18" height="18" x="26" y="3" ry="50" rx="50"></rect>
                    </svg>
                    <!--<span class="sr-only"  v-if="!missionSelected">{{ t("results.no") }}</span>
                    <span class="sr-only"  v-if="missionSelected">{{ t("results.yes") }}</span>-->
                    <span class="switcher__label" v-if="missionSelected">{{ t("results.yes") }}</span>
                    <span class="switcher__label" v-else>{{ t("results.no") }}</span>
                </button>
            </div>
            <div class="accordion__dropdown">
                <!---End of switcher button-->
                <div aria-labelledby="purcentage-adequation" class="purcentage">{{ percentage }}{{ t("results.compatibility_score") }}</div>
                <!---Begin of dropdown button-->
                <button class="dropdown-btn" :aria-controls="'accordion-panel-'+mission" :aria-expanded="showDetail" :id="'accordion-header-'+mission" type="button" >
                    <span class="accordion-icon"></span>
                    <span class="sr-only"> {{ t("results.roles."+mission+".title") }}</span>
                </button>
                <!---End of dropdown button-->
            </div>
        </div>
        <section class="accordion__content" :aria-labelledby="'accordion-header-'+mission" :id="'accordion-panel-'+mission" role="region" :aria-hidden="!showDetail">
            <ul>
                <li>
                    <p>
                        <span class="grey-round"></span>
                        <span v-html="DOMPurify.sanitize(t('results.roles.'+mission+'.point_1'))"></span>
                    </p>
                </li>
                <li>
                    <p>
                        <span class="grey-round"></span>
                        <span v-html="DOMPurify.sanitize(t('results.roles.'+mission+'.point_2'))"></span>
                    </p>
                </li>
                <li>
                    <p>
                        <span class="grey-round"></span>
                        <span v-html="DOMPurify.sanitize(t('results.roles.'+mission+'.point_3'))"></span>
                    </p>
                </li>
            </ul>
        </section>
    </li>
</template>


<script>
import { onMounted, defineComponent, toRefs, ref } from "vue";
import { useI18n } from 'vue-i18n';
import DOMPurify from 'dompurify'
import { useCandidateStore } from '@/stores/candidate'

export default defineComponent({
  name: "AccordionElement",
  components: {},
  props: {
        mission: {
            type: Text,
            required: true
        },
        percentage: {
            type: Number,
            required: true
        }
    },
  setup(props) {
    const { t } = useI18n({})
    const { mission, percentage } = toRefs(props)
    const showDetail = ref(false)
    const missionSelected = ref(true)
    const candidate = useCandidateStore()

    const existingItem = candidate.choices.indexOf(props.mission)

    if( existingItem === -1){
        missionSelected.value = false
    }

    function handleShowDetail() {  
        showDetail.value = !showDetail.value
    }

    function handleModifyChoice() {
        missionSelected.value = !missionSelected.value

        if(missionSelected.value == false){
            candidate.removeChoice(props.mission)
        }else{
            candidate.addChoice(props.mission)
        }
    }

    onMounted(() => {

    })

    return {
            t,
            showDetail,
            missionSelected,
            handleShowDetail,
            handleModifyChoice,
            DOMPurify
        }
  }
})
</script>
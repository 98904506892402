<template>
    <div class="question-example__header">
        <img class="small-logo" src="/dist/img/logo-anim-gold.svg" alt="Logo JO Paris 2024">
    </div>
</template>

<script>
export default {
    name: "HeaderBanner",
}
</script>